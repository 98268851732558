











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question499',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: '5-methylhexan-2-al', value: '5al'},
        {text: '5-methylhexan-2-one', value: '5one'},
        {text: '2-methylhexan-5-one', value: '2one'},
        {text: '2-methylhexan-5-al', value: '2al'},
      ],
      optionsFr: [
        {text: '5-méthylhexan-2-al', value: '5al'},
        {text: '5-méthylhexan-2-one', value: '5one'},
        {text: '2-méthylhexan-5-one', value: '2one'},
        {text: '2-méthylhexan-5-al', value: '2al'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/C7H14O_nomenclature1.png';
    },
  },
};
